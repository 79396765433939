.title-container{
  padding-right:10px;
}
.header-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #283e4a;
  background-size: cover;
  height: 52px;
  color: white;
  overflow: auto;
  
}
.error{
  background-color: whitesmoke;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  color: red;
  font-size: 20px;
  font-weight: 600;
}
.blank-space{
  display: none;
}
.ecoacme-title{
  color: white;
  font-size: 20px;
  margin: 0px,30px,0px 30px;
  
}
.header-ecoacme-link{
  text-decoration-line: none;
  
}
/* .title-container{
  position: relative;
  right: 20px;
  margin: 0px,30px,0px 30px;
} */
.log-search-container{
    display: flex;
    
}
.header-login-component{
  display: none;
}
/* .logoimg-container{
  max-width: 70px;
  height: 30px;
  
} */
/* .logo-img{
  max-width:
} */
.username-container{
    padding-right: 5px;
}
.password-container{
    padding-right: 5px;
}
.label-password{
  padding-bottom: 2px;
    display: block;
}
.label-username{
  padding-bottom: 2px;
    display: block;
}
.header-username{
  height: 20px;
}
.header-password{
  height: 20px;
}
.header-login-button{
    margin-left: 5px;
    
}
.header-search{
    width: 244px;
    height: 30px;
}
.header-search-button{
    height: 30px;
}

.header-links{
    text-decoration: none;
    color: white;
    padding: 10px;
}

.nav-container{
  display: none;
   
    
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 0px;
  }
   
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
   
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
   
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
   
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
   
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
   
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    
  }
   
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
   
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
   
  /* Individual item */
  .menu-item {
    display: block;
    margin-bottom: 10px;
    color: white;
  }
   
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  @media(min-width:520px){
    .title-container{
      position: absolute;
      left: 10px;
    }
    
    .ecoacme-title{
  
      font-size: 24px;
      margin: 0px,30px,0px 0px;
    }
    
    .log-search-container{
      display: flex;
  }
  .header-login-component{
    display: initial;
  }
    
  .username-container{
    padding-right: 5px;
}
  
    .nav-container{
      display: flex;
    }

    .bm-burger-button {
      display: none;
    }
    .bm-menu {
      display: none;
      
      
    }
    
    .header-container{
      justify-content: space-between;
    }
  }
  @media(min-width: 250px){
    .blank-space{
      display: initial;
    }
  }
  