.users-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 181px;
    height: 282px;
    border: lightgray 1px solid;
    margin: 0px 15px 20px 0px;
}
.user-img{
    height: 100px;
}
.userpage-card{
    display: flex;
    justify-content: center;
    width: 157px;
    height: 174px;
    padding: 12px;
    text-align: center;
    align-items: center;
}
.userpage-name{
    color: black;
    font-weight: 600;
}
.userpage-profession{
    font-size: 14px;
    color: gray;
    font-weight: 400;
}
.purple-box{
    background-color:gray;
    width: 100%;
    height: 40px;
}
.connect-button{
    margin-top: 10px;
}