.eachpost-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: rgb(165, 156, 156) solid 1px;
    overflow: auto;
    margin-bottom: 5px;
    background-color: white;
    padding: 10px;
}


.postuser-image{
    height: 60px;
    margin-right: 10px;
}
.post-img{
    height: 300px;
    
    
}
.post-date{
    font-size: 10px;
}

.post-img-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.post-imgname-container{
    display: flex;
    

}
.post-imgname-link{
    text-decoration: none;
    color: black;
}
.name-profession-list{
    display: block;
    
}
.post-name{
    font-weight: 600;
}
.post-profession{
    font-size:12px;
    color:rgb(141, 6, 101);
}


.line-seperator{
    background-color: lightgray;
    height: 1px;
    width: 100%;
    margin-bottom: 12px;
}

.hide-imgdelete{
    display: none;
}
.editpost-form-container{
    height: 100%;
    background-color: white;
    
}


@media(min-width: 720px){

    .post-img{
        display: flex;
        justify-content: center;
        width: 100%;
        
        
    }
    .post-img-container{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .post-icon-button{
        margin-left: 1px;
        font-size: 7px;
        padding-left: 0px;
        
    }
    .icon-box{
        justify-content: center;
    }
    
}



@media(min-width: 928px){
    .post-icon-button{
        font-size: 12px;
        
    }
    .icon-box{
        justify-content: initial;
    }
}