.start-info{
    max-width: 70%;
    margin-right: 5px;
    padding: 5px; 
}
.signup{
   max-width: 70%;
   text-align: left;
   padding: 5px;
   margin-bottom: 20px;
}

.start-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    padding: 0px 30px 0px 30px;
}

.signup-input{
    padding: 10px;
    margin: 5px;
    border-radius: 10px
    
}

@media(min-width:580px){
    .start-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-height: 100%;
    }
    .start-info{
        max-width: 50%;
        padding: 5px; 
    }
    .signup{
        max-width: 50%;
        text-align: left;
        padding: 5px;
     }
}