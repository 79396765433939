.post-form-container{
    height:100%;
}



 .blue-bar{
    background:rgb(141, 6, 101);
    width: 100%;
    height: 10%;
} 
.post-image-url{
    width: 100%;
}
.home-writecomment{
    height: 95%;
    width: 100%
}
.writecomment-form{
    height: 30px;
    width: 90%
}
.share-postform{
    height: 70%;
}
.share-post-button{
    margin-bottom: 10px;
}