.delete-confirmation-box{
    background-color: white;
    border: solid 1px black;
    position: relative;
    color: blue;
    font-size: 20px;
    z-index: 1;
    width: 100%;
    height: 170px;
}
.hidden-commentbox{
    display: none;
    
}
.hidden-deletecomment{
    display: none;
}
.icon-box{
    display: flex;
    position: relative;
    bottom: 0px;
    
}
.post-icon-button{
    background-color: white;
    display: flex;
    font-size: 12px;
    border: 0;
    align-items: center;
    margin-left: 3px;
    cursor: pointer;
    outline-color: purple;
}
.post-icon-button:hover{
    background-color: lightgray;
    
}
.comment-img{
    height: 30px;
    
}

.post-comment-text{
    border: lightgray 1px solid;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    border-radius: 20px;
    padding: 14px 8px 4px 8px;
    
    
}
.display-deletecomment{
    display: initial;
    font-weight: bold;
    font-size: 20px;
    color: rgb(141, 6, 101);
}

.hidden-commenttext{
    display: none;
}
.comm-container{
    display: flex;
    padding: 10px 5px 5px 0px
}
.display-commenttext{
   width: 100%;
    background: #f3f6f8;
    border-radius: 0 0 20px 20px;
    font-size: 100%;
    vertical-align: baseline;
    font-weight: 400;
    color: rgba(0,0,0,.9);
    padding: 5px 0px 12px 12px;
}
.comm-info{
    display: flex;
    justify-content: space-between;
    
}
.comm-date{
    font-size: 8px;
    padding: 0px 0px 5px 10px;
   
}

.comments-img{
    height: 50px;
    padding: 5px;
}
.comm-list{
    list-style-type: none;
    font-size: 14px;
}
.comm-first-name{
    font-weight: 600;
    
}
.comm-profession{
    font-size: 10px;
    color: rgb(141, 6, 101)
}
.comm-delete-button{
    display: block;
    margin-left: 50px;
}