.searchfriend-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    width: 100%;
    height: 50px;
}
.search-friends{
    height: 30px;
    width: 90%
}
.friendlist-container{
    background-color: white;
    margin: 30px 30px 30px 30px;
}

.friend-contain{
    padding: 5px 30px 5px 30px;
}

