.userpage-container{
    
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    
}
.user-middle-container{
    background-color: white;
    padding: 0px 30px 0px 30px;
}
.usermiddle-title{
    margin: 16px 0px 16px 0px;
    
}
.user-middle{
    
    display: flex;
    flex-wrap: wrap;
    /* flex-basis: 500px; */
    flex-basis: 100%;
    
    
}
.usersearch-button{
    border: 1px solid white;
    background-color: lightgray;
    height: 30px;
    color: purple;
    margin-left: 2px;
}
.user-sidebar{
    background-color: white;
    border: lightgray 1px solid;
    width: 310px;
    height: 180px;
    margin-right: 50px;
}
.userpage-friends{
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
}
.friend-image{
    height: 30px;
    
}
.friendtext-andicon{
    margin-right: 10px;
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.friend-number{
    display: flex;
    align-items: center;
}

@media(max-width: 650px){
    .userpage-container{
    
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        
    }
    .user-sidebar{
        background-color: white;
        border: lightgray 1px solid;
        width: 100%;
        height: initial;
        margin: 0px;
        
    }
    .user-middle{
    
        display: flex;
        flex-wrap: wrap;
        /* flex-basis: 500px; */
        flex-basis: 100%;
        justify-content: center;
        
        
    }
    
}