.profile-img{
    height: 70px;
    
   
}
.userbadge-link{
    display: flex;
    justify-content: center;
}

.eachprofile-item{
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: auto;
}

.profile-eachuser-container{
    width: 200px;
}