.user-message-img{
    width: 40px;
}

.usermessage-submitbutton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border: lightgray 1px solid;
    border-left:0px;
    border-top: 0px;
    border-right: 0px;
    width: 280px;
    height: 92px;
    outline-color: purple;
}

@media(max-width: 750px){
    .usermessage-submitbutton{
        background-color: white;
        border: lightgray 1px solid;
        border-left:0px;
        border-top: 0px;
        border-right: 0px;
        width: 200px;
        height: 92px;
        outline-color: purple;
    }
}

@media(max-width: 600px){
    .usermessage-submitbutton{
        background-color: white;
        border: lightgray 1px solid;
        border-left:0px;
        border-top: 0px;
        border-right: 0px;
        width: 100%;
        height: 92px;
        outline-color: purple;
    }
}
@media(max-width: 466px){
    
}