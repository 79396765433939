.notfound-ecoacme-title{
    padding: 20px;
    font-weight: 600;
    color: black;
    margin-top: 10px;
}

.notfoundpage-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notfoundpage-text{
    
    width: 70%;
    text-align: center;
}
.notfound-p{
    font-size: 18px;
    color: black;
    
}