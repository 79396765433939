.searchmessage-container{
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    width: 100%;
    height: 50px;
   
}
.messaging{
    display: flex;
}
.handle-newmessage{
    cursor: pointer;
}
.messaging-container{
    padding: 0px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: lightgray 1px solid;
    border-top: 0px;
    border-right: 0px;
    min-height: 40px;
    border-left: 0px;
   
}
.messaging-icon{
    height: 30px;
}

.search-message{
    height: 30px;
    width: 90%
}

.messagelist-container{
    margin: 30px; 
    background-color: white;
    display: flex;
    /* justify-content: space-between; */
    border: lightgray 1px solid;
    min-height: 600px;

}

.newmessage-button{
    position: relative;
    z-index: 1;
   
}
.hidden{
    display: none;
}
.hidden-messages{
    display: none
}
.display-messages{
    
    display:relative;
    width: 100%;
    height: 40px;
}
.usermessageinfo-container{
    border-right: 1px solid lightgray;
}
.messageconvo-container{
    height: 300px;
    border-bottom: lightgray solid 1px;
    overflow: auto;
    padding: 10px 30px 10px 30px;
}
.replymessage-container{
    width: 100%
}

@media(max-width: 890px){
    .messageconvo-container{
        
        padding: 10px 10px 10px 10px;
    }
    .messagelist-container{
        margin: 0px; 
        
    
    }
}
@media(max-width: 466px){
    .messaging-icon{
        display: flex;
        justify-content: center;
        height: 20px;
    }
    .messaging{
        display: none;
        font-size: 12px;
    }
    
}

