.profile-container{
    
    margin: 40px;
}
.myprofile-img{
    height: 200px;
}
.profile-info-img{
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
    
   
}
.graybar{
    width: 100%;
    height: 70px;
    background-color: gray
}
.profile-info{ 
    display: flex;
    justify-content: space-between;
}
.profile-page-name{
    font-weight: 600;
    
}
.profile-profession-college{
    font-size: 12px;
    font-weight: 600;
}
.profile-aboutme{
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.profile-services{
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.profile-organizations{
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.aboutMeIcon{
    display: flex;
    justify-content: space-between;
}
.aboutMeForm{
    width: 100%;
    height: 200px;
}
.myprofile-editicon{
    height: 40px;
}
@media(max-width: 480px){
    .profile-info{ 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile-info-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        
       
    }
    .profile-aboutme{
        font-size: 12px;
    }
    .profile-services{
        font-size: 12px;
    }
    .profile-organizations{
        font-size: 12px;
    }
    .myprofile-title{
        font-size: 16px;
    }
    .myprofile-editicon{
        height: 20px;
    }
}