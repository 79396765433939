.friendpage-info{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.friends-link{
    color: black;
    text-decoration: none;
}
.friendpage-friendcontainer{
    display: flex;
    align-items: center;
}
.friendpage-img{
    width: 90px;
    padding-right: 10px;
}

.gray-bar{
    position: relative;
    height: 1px;
    width: 100%;
    background-color: lightgray;
    margin: 20px 0px 20px 0px;
    
}
.friendpage-message{
    position: relative;
    border: 1px solid purple;
    padding: 5px;
    font-weight: 600;
    color: purple;
    cursor: pointer;
}
.friendpage-name{
    font-weight: 600;
}
.friendname-profession-college{
    font-size: 14px;
}
.display-friendmessage{
    border: lightgray 1px solid;
    width: 100%;
    height: 230px;
    background-color: white;
}

@media(max-width: 462px){
    .friendpage-info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
    }
    .friendpage-friendcontainer{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .friendpage-message{
        position: relative;
        border: 1px solid purple;
        padding: 5px;
        font-weight: 600;
        color: purple;
        cursor: pointer;
        margin-right: auto;
        margin-left: auto;
    }
}

