.messagepage-username{
    font-size: 12px;
    font-weight: 600;
    display: flex;
    
    align-items: center;
}
.messagepage-container-purple{
   display: flex;
   justify-content: flex-end;
   margin-bottom: 20px;
   overflow: auto; 
}
.mcp{
    background-color: purple;
    border-radius: 30px;
    padding: 10px 10px 10px 20px;
    width: 300px;
    color: white;
    overflow: auto;
    
}

.messagepage-container-gray{
   display: flex;
   justify-content: flex-start;
   margin-bottom: 20px; 
   overflow: auto;

}

.mcg{
    display: flex;
    flex-direction: column;
   background-color: gray;
   border-radius: 30px;
   padding: 10px 10px 10px 20px;
   width: 300px;
   align-items: space-around;
   color: white;
   overflow: auto;
}
.messagepage-username-profession{
    display: flex;
    flex-direction: column;
    border: lightgray 1px solid;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    width: 100%;
    min-height: 40px;
    padding: 0px 10px 0px 10px;
    overflow: auto;
}
.messagepage-profession{
    font-size: 12px;
}
@media(max-width: 890px){

    .mcg{
        display: flex;
        flex-direction: column;
       background-color: gray;
       border-radius: 30px;
       padding: 10px 10px 10px 20px;
       width: 250px;
       align-items: space-around;
       color: white;
    }
    
    .mcp{
    background-color: purple;
    border-radius: 30px;
    padding: 10px 10px 10px 20px;
    width: 250px;
    color: white;
    
    }
    @media(max-width: 600px){
        .mcg{
            display: flex;
            flex-direction: column;
           background-color: gray;
           border-radius: 30px;
           padding: 10px 5px 10px 20px;
           
           align-items: space-around;
           color: white;
           
        }
        
        .mcp{
        
        background-color: purple;
        border-radius: 30px;
        padding: 10px 5px 10px 20px;
        
        color: white;
        
        }
    } 
}
@media(max-width: 350px){
    .mcg{
        display: flex;
        flex-direction: column;
       background-color: gray;
       border-radius: 30px;
       padding: 10px 5px 10px 20px;
       width: 150px;
       align-items: space-around;
       color: white;
       
    }
    
    .mcp{
    
    background-color: purple;
    border-radius: 30px;
    padding: 10px 5px 10px 20px;
    width: 150px;
    color: white;
    
    }
}


